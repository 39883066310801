import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useAuthenticator } from '@aws-amplify/ui-react'
import { Loader, platformKeysEnum } from '@motrix/core'

import { getWorkspaceFromCookie } from './workspaces/cookie-store'
import { PermissionsProvider } from './permissions'

const initialState = {
  accessToken: '',
  availablePlatforms: [] as Array<{
    platform: string
    url: string
    label: string
  }>,
  currentWorkspace: {} as {
    id: string
    label: string
    name: string
    actions: Array<string>
  },
  email: '',
  familyName: '',
  givenName: '',
  id: '',
  idToken: '',
  loggedIn: false,
  memberships: [] as Array<{
    id: string
    workspace: {
      id: string
      label: string
      name: string
    }
    roles: Array<{
      id: string
      label: string
      value: string
      actions: Array<string>
    }>
  }>,
  pictureUrl: '',
  username: '',
}

export const AuthenticationContext = createContext(initialState)



export const useAuthentication = () => {
  const me = useContext(AuthenticationContext)
  const authenticator = useAuthenticator()
  const hasAccess = (platformKey: platformKeysEnum) => {
    return me.availablePlatforms.some(
      ({ platform }) => platform === platformKey,
    )
  }

  const redirectTo403 = () => {
    const currentLocation = window.location.pathname
    if (currentLocation === '/403') {
      return
    }
    window.location.href = '/403'
  }
  return {
    ...authenticator,
    me,
    redirectTo403,
    hasAccess,
  }
}

export const workspaceIdHeaderName = 'x-workspace-id'

export const Provider = ({ children }: PropsWithChildren) => {
  const { user, signOut } = useAuthenticator()
  const [state, setState] = useState(initialState)

  useEffect(() => {
    const cb = async () => {
      const accessToken =
        user.getSignInUserSession()?.getAccessToken().getJwtToken() ?? ''
      const idToken =
        user.getSignInUserSession()?.getIdToken().getJwtToken() ?? ''

      const headers = new Headers()
      headers.set('Authorization', `Bearer ${accessToken}`)

      const workspaceId = getWorkspaceFromCookie()
      if (workspaceId) {
        headers.append(workspaceIdHeaderName, workspaceId)
      }

      const request = await fetch(`${process.env.API_URL}/me`, {
        headers,
      })

      if (request.status === 401) {
        signOut()
        return
      }

      const response = await request.json()
      setState({
        accessToken,
        availablePlatforms: response.availablePlatforms,
        currentWorkspace: response.currentWorkspace,
        email: user.attributes?.email ?? '',
        familyName: user.attributes?.['family_name'] ?? '',
        givenName: user.attributes?.['given_name'] ?? '',
        id: response.id,
        idToken,
        loggedIn: true,
        memberships: response.memberships,
        pictureUrl: response.pictureUrl,
        username: user.username ?? '',
      })
    }

    cb()
  }, [user])

  return (
    <AuthenticationContext.Provider value={state}>
      {!state.loggedIn && <Loader />}
      {state.loggedIn && <PermissionsProvider>{children}</PermissionsProvider>}
    </AuthenticationContext.Provider>
  )
}
