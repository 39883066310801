import { ComponentProps, ElementType } from 'react'

import { styled } from '@motrix/tokens'

export interface FlexProps extends ComponentProps<typeof Flex> {
  as?: ElementType
}

export const Flex = styled('div', {
  defaultVariants: {
    align: 'center',
    direction: 'row',
    gap: 2,
    justify: 'start',
  },

  display: 'flex',

  variants: {
    align: {
      baseline: {
        alignItems: 'baseline',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
      start: {
        alignItems: 'flex-start',
      },
      stretch: {
        alignItems: 'stretch',
      },
    },
    direction: {
      column: {
        flexDirection: 'column',
      },
      row: {
        flexDirection: 'row',
      },
    },
    gap: {
      0: {
        gap: 0,
      },
      1: {
        gap: '$1',
      },
      2: {
        gap: '$2',
      },
      3: {
        gap: '$3',
      },
      4: {
        gap: '$4',
      },
      5: {
        gap: '$5',
      },
      6: {
        gap: '$6',
      },
      7: {
        gap: '$7',
      },
      8: {
        gap: '$8',
      },
      9: {
        gap: '$9',
      },
    },

    justify: {
      around: {
        justifyContent: 'space-around',
      },
      baseline: {
        justifyContent: 'baseline',
      },
      between: {
        justifyContent: 'space-between',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
      normal: {
        justifyContent: 'normal',
      },
      start: {
        justifyContent: 'flex-start',
      },
      stretch: {
        justifyContent: 'stretch',
      },
    },
  },
})
