export const workspaceCookieName = 'workspaceId'

export const getWorkspaceFromCookie = () => {
  const cookieName = `${workspaceCookieName}=`
  const cookies = decodeURIComponent(document.cookie).split('; ')
  const currentWorkspaceIdFromCookie = cookies
    .find((c) => c.startsWith(cookieName))
    ?.split('=')?.[1]

  return currentWorkspaceIdFromCookie
}

export const setWorkspaceCookie = (value: string) => {
  document.cookie = `${workspaceCookieName}=${value}`
}
