export const taggingPermissions = (actions: Set<string>) => {
  const VIEW_ALL_OBJECTS = actions.has('view-all-object')
  const EDIT_OBJECT = actions.has('edit-object')
  const ASSIGN_OBJECT = actions.has('assign-object')
  const UNASSIGN_OBJECT = actions.has('unassign-object')
  const EDIT_OBJECT_METADATA = actions.has('edit-object-metadata')

  return {
    ASSIGN_OBJECT,
    EDIT_OBJECT,
    EDIT_OBJECT_METADATA,
    UNASSIGN_OBJECT,
    VIEW_ALL_OBJECTS,
  } as const
}
